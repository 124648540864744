import React from "react";
import SideNav from "./SideNav";
import Header from "./Header";
import Footer from "./Footer";

function TeamMemberModal({ id, name, role, socialLinks, bio }) {
  return (
    <div
      className="modal fade"
      id={id}
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered modal-lg">
        <div className="modal-content border-0">
          <div className="modal-header flex-column">
            <h3 className="modal-title" id="exampleModalLabel">
              {name}
            </h3>
            <h6 className="text-muted">{role}</h6>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <div className="row mb-4">
              <div className="col-lg-12 col-12 vision-text">
                <p
                  style={{ textAlign: "justify" }}
                  dangerouslySetInnerHTML={{ __html: bio }}
                ></p>
              </div>
            </div>
            {/* <ul className="social-icon">
              <li className="me-3">
                <strong>Where to find?</strong>
              </li>
              {socialLinks.map((link, index) => (
                <li key={index}>
                  <a
                    href={link.url}
                    className={`social-icon-link ${link.icon}`}
                  ></a>
                </li>
              ))}
            </ul> */}
          </div>
        </div>
      </div>
    </div>
  );
}

function Executives() {
  const teamMembers = [
    {
      id: "patrick-essien",
      img: "pic1_1.jpeg",
      name: "Gladys Gordon ",
      role: "President",
      socialLinks: [
        { url: "#", icon: "bi-facebook" },
        { url: "#", icon: "bi-instagram" },
      ],
      bio: `
      `,
    },
    
    {
      id: "kuta",
      img: "pic2.jpeg",
      name: "Samuel Awotwi ",
      role: "Administrator",
      socialLinks: [
        { url: "#", icon: "bi-twitter" },
        { url: "#", icon: "bi-linkedin" },
        { url: "#", icon: "bi-envelope" },
      ],
      bio: ``
    },
 
    {
      id: "don",
      img: "pic3.jpeg",
      name: "Rev. Augustine Amoako Antwi",
      role: "Director",
      socialLinks: [
        { url: "#", icon: "bi-youtube" },
        { url: "#", icon: "bi-whatsapp" },
        { url: "#", icon: "bi-instagram" },
      ],
      bio: `Stephen Nyankson is a dedicated IT professional with a Bachelor's Degree in Computer Science from the University of Cape Coast. 
      He currently works at the University of Cape Coast as IT support Specialist and doubles as Backend developer at Copianto AI.
      Stephen joined the Foundation in November 2023.<p/>
      Stephen is not only committed to advancing his career in Software Engineering but also to giving back to society through roles at 
     MOLEX Foundation Africa. With a passion for technology and a drive to make the world more connected, he is eager 
      to contribute his expertise to drive organizational objectives while continually improving himself.`,
    },
    {
      id: "kelly",
      img: "pic4.jpeg",
      name: "Christie Davies",
      role: "Director",
      socialLinks: [
        { url: "#", icon: "bi-facebook" },
        { url: "#", icon: "bi-instagram" },
      ],
      bio: `<p>Born and raised in Nyanfeku Ekroful, in the Abura-Asebu-kwamankese District of Central Ghana, Mr. Ato-Turkson Daniel's journey embodies a commitment to education and community service. Beginning his schooling in Nyanfeku Ekroful, he later pursued his secondary education at Academy of Christ the King and later moverd to Sammo Otoo to better the result where is studiedBusinessas a course, both located in Cape Coast, Central Region.</p>
      <p>In 2017, he embarked on a career path, starting as a Sales Executive Officer at AFB Finance (now LetSheGo Savings and Loans), before transitioning to TF Finance Service as a Loan Master. </p>
      
      <p>In 2019, he furthered his education at the Premier and largest University in Ghana, University of Ghana- Legon to pursue B.A Accounting and Sociology.</p>
      
     <p> Deeply passionate about service, Mr. Ato-Turkson has been actively involved in numerous community organizations, including the Future Leaders Advocacy Group (FLAG), Abura Asebu Kwamankese Tertiary Student and Associates (AAKTSA), Nature Matters Foundation, EmmCom Foundation, ENACTUS UG. He has led various initiatives focusing on youth empowerment, environmental sustainability, and societal responsibility. His guiding principle, "No one owns us a responsibility but we own the society a responsibility," underscores his dedication to effecting positive change.</p>
      
      Currently, Mr. Ato-Turkson serves as a Service Personnel at the University of Cape Coast Directorate of Academic Affairs, working within the Admissions Section.`,
    },    
    
  ];

  return (
    <main>
      <Header />

      <section className="testimonial section-padding">
        <div className="container">
          <div className="row">
            <div className="row col-lg-12">
              <div className="col-12 text-center">
                <h2 className="mb-lg-5">
                  <span>Executives</span>
                </h2>
              </div>
              {teamMembers.map((member) => (
                <div
                  key={member.id}
                  className="col-lg-3 mb-4 col-12 align-items-center"
                >
                  <div className="team-thumb ">
                    <img
                      src={`images/team/${member.img}`}
                      className="img-fluid custom-circle-image team-image me-4"
                      alt=""
                    />
                    <div className="team-info text-center">
                      <div className="mb-0 capitalize">{member.name}</div>
                      <strong className="text-muted">{member.role}</strong>
                      <br />
                      {/* <button
                        type="button"
                        className="btn btn-outline-dange custom-modal-btn"
                        data-bs-toggle="modal"
                        data-bs-target={`#${member.id}`}
                      >
                        Read more
                        <em className="mdi mdi-arrow-right-thin"></em>

                      </button> */}
                    </div>
                  </div>
                </div>
              ))}
            </div>
            {/* <SideNav /> */}
          </div>
        </div>
      </section>

      {/* Team Member Modals */}
      {teamMembers.map((member) => (
        <TeamMemberModal
          key={member.id}
          id={member.id}
          name={member.name}
          role={member.role}
          socialLinks={member.socialLinks}
          bio={member.bio}
        />
      ))}

      <Footer />
    </main>
  );
}

export default Executives;
