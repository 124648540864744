import { React, useEffect, useState, useRef } from "react";
import { Button, Modal } from "react-bootstrap";

import Header from "./Header";
import Footer from "./Footer";
import Bannar from "./Bannar";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "./css/Home.css";
import "slick-carousel/slick/slick-theme.css";
import YouTubeEmbed from "./YouTube";

function NavItem({ id, text, active }) {
  return (
    <li className="nav-item" role="presentation">
      <button
        className={`nav-link${active ? " active" : ""}`}
        id={id}
        data-bs-toggle="pill"
        data-bs-target={`#${id}`}
        type="button"
        role="tab"
        aria-controls={id}
        aria-selected={active}
      >
        {text}
      </button>
    </li>
  );
}

function TabContent({ id, active, children }) {
  return (
    <div
      className={`tab-pane fade${active ? " show active" : ""}`}
      id={id}
      role="tabpanel"
      aria-labelledby={`${id}-tab`}
    >
      {children}
    </div>
  );
}
function useIntersectionObserver(callback, options) {
  const elementsRef = useRef([]);

  useEffect(() => {
      const observer = new IntersectionObserver((entries) => {
          entries.forEach((entry) => {
              if (entry.isIntersecting) {
                  callback(entry.target);
              } else {
                  entry.target.classList.remove("visible"); // Remove the class when out of view
              }
          });
      }, options);

      elementsRef.current.forEach((el) => observer.observe(el));

      return () => observer.disconnect();
  }, [callback, options]);

  return elementsRef;
}

export default function Home() {
  const missionRef = useRef(null);
  const visionRef = useRef(null);
  const [countdown, setCountdown] = useState(0);
  const coreValuesRef = useRef(null);
  const [show, setShow] = useState(true);

  // const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);
  const [showToast, setShowToast] = useState(true);

  const handleClose = () => {
    setShowToast(false);
  };

  const opts = {
    height: "300",
    width: "400",
    playerVars: {
      autoplay: 1,
    },
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true, // Enable auto-scrolling
    autoplaySpeed: 4000, // Set auto-scroll speed in milliseconds
  };

  const apiUrl = "https://fresh-link.onrender.com";

  function fetchData() {
    fetch(apiUrl)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        console.log("Data received:", data);
        // Handle the data as needed
      })
      .catch((error) => {
        console.error("Error:", error);
        // Handle errors
      });
  }

  // Call fetchData every 10 seconds
  setInterval(fetchData, 10000);

  const elementsRef = useIntersectionObserver((element) => {
    element.classList.add("visible");
}, { threshold: 0.1 });


  return (
    <div>
      <Header />
      {/* <section className="preloader">
                <div className="spinner">
                    <span className="sk-inner-circle"></span>
                </div>
            </section> */}

      <section className="slick-slideshow">
        <Slider {...settings} className="">

        <div className="slick-custom">
            <img
              src="images/slideshow/20220715_164127.jpg"
              className="img-fluid"
              alt=""
            />

            <div className="slick-bottom">
              <div className="container">
                <div className="row">
                  <div className="col-lg-6 col-12">
                    <h2 className="slick-title">Lady Gladys Gordon Foundation </h2>
                    <h6 className="slick-title mt-lg-3 mb-lg-5 show-">
                    Building a bright future for blind students.              
                   
                    </h6>
                    <Link to="/contact" className="btn custom-btn">
                      Get in touch
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="slick-custom">
            <img
              src="images/slideshow/20210509_164422.jpg"
              className="img-fluid"
              alt=""
            />

            <div className="slick-bottom">
              <div className="container">
                <div className="row">
                  <div className="col-lg-6 col-12">
                    <h3 className="slick-title">Our Vision</h3>
                    <h6 className="slick-title mt-lg-3 mb-lg-5 show-">
                    Support blind students in unearthing and harnessing
                    their unique talents.
                                        </h6>
                    <Link to="/contact" className="btn custom-btn">
                      Get in touch
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>



          <div className="slick-custom">
            <img
              src="images/slideshow/20220708_144901.jpg"
              className="img-fluid"
              alt=""
            />

            <div className="slick-bottom">
              <div className="container">
                <div className="row">
                  <div className="col-lg-6 col-12">
                    <h3 className="slick-title">Our Mission</h3>

                    <h6 className="slick-title mt-lg-3 mb-lg-5 show-">
                    To create a conducive atmosphere for Blind students to unearth their talents and improving the quality of life for them.
                    </h6>

                    <Link to="/about" className="btn custom-btn">
                      Learn more about us
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="slick-custom">
            <img
              src="images/slideshow/2A1A1609.jpg"
              className="img-fluid"
              alt=""
            />

            <div className="slick-bottom">
              <div className="container">
                <div className="row">
                  <div className="col-lg-6 col-10">
                    <h1 className="slick-title">Women in STEM</h1>
                    <p className="lead text-light mt-lg-3 mb-lg-5">
                      We spike students' interest in Science Technology
                      Engineering and Mathematics (STEM) especially the
                      girl-child.
                    </p>
                    <Link to="/contact" className="btn custom-btn">
                      Get in touch
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </Slider>
      </section>

      <section className="about section-paddilng">
        <div className="container border-colori">
          <div className="row border-colori">
            <div className="col-lg-12 col-12 border-colori">
              <div className="tab-content mt-2" id="pills-tabContent">
                <TabContent id="pills-home" active>
                  <div className="row border-coloro">
                    <div className="col-lg-6 col-12">
                      <img
                        src="images/ladygladys/20230709_164825_1.jpg"
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                    <div className="col-lg-12 col-12">
                      <div className="d- flex-column justify-content-center h-100 ms-lg-4 mt-lg-0 mt-5">
                        <h4 className="mb-3">
                          <span> A Holistic Approach to Empowering Blind Students</span>{" "}
                        </h4>
                        {/* <p>Little Fashion templates comes with <Link to="sign-in.html">sign in</Link> / <Link to="sign-up.html">sign up</Link> pages, product listing / product detail, about, FAQs, and contact page.</p> */}
                        <p className="b-text">
                        The Lady Gladys Gordon Foundation emerges as a resolute advocate for the empowerment and development of blind students, tirelessly committed to creating an environment where their potential can flourish. Central to our mission is the unwavering belief that every blind pupil deserves not just recognition of their rights but also the essential tools and support to actualize those rights. We are dedicated to dismantling barriers that impede their educational journeys, ensuring that they have equitable access to opportunities that allow them to pursue their dreams and aspirations.
                        </p>
                        <div className="mt-0 mt-lg-auto">
                          <Link
                            to="/about"
                            ref={coreValuesRef}
                            className="custom-link text-primary mb-2"
                          >
                            Learn more about us
                            <i className="bi-arrow-right ms-2"></i>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </TabContent>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <YouTubeEmbed videoId="k8x84hOB4P4" /> */}

      <section className="container-fluid activity-bg mt-4 mb-5">
        <h4 className="container text-center mt-1 mb-0">
        <span> Other Activities</span>{" "}
        </h4>
        <div
          className="row container-"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <div class="card" className="col-12 col-lg-3 activity" style={{}}>
            <img
              class="card-img-top"
              src="images/ladygladys/20220213_132445_1.jpg"
              alt="Card image"
            />
            <div class="card-body">
              <h6 class="card-title">2023 donation to the blind pupils of Cape Coast School for the Deaf/ Blind.</h6>
              {/* <p class="card-text">Some example text.</p> */}
              {/* <a href="#" class="btn btn-primary">See Profile</a> */}
            </div>
          </div>


          <div class="card" className="col-12 col-lg-3 activity" style={{}}>
            <img
              class="card-img-top"
              src="images/ladygladys/IMG_20240721_151606_1.jpg"
              alt="Card image"
            />
            <div class="card-body">
              <h6 class="card-title">2024 donation to the blind pupils of Cape Coast School for the Deaf/ Blind.</h6>
              {/* <p class="card-text">Some example text.</p> */}
              {/* <a href="#" class="btn btn-primary">See Profile</a> */}
            </div>
          </div>

          <div class="card" className="col-12 col-lg-3 activity" style={{}}>
            <img
              class="card-img-top"
              src="images/ladygladys/20220710_200137_1.jpg"
              alt="Card image"
            />
            <div class="card-body">
              <h6 class="card-title">
              Citation to FM stations that offered a platform for us to publicize our programmes.
              </h6>
              {/* <p class="card-text">Some example text.</p> */}
              {/* <a href="#" class="btn btn-primary">See Profile</a> */}
            </div>

            
          </div>

                          {/* <img
                            src="images/ladygladys/20220708_144914.jpg"
                            className="header-image border-bottom-right-radius  img-fluid col-12 col-lg-4"
                            alt=""
                          /> */}
        </div>
      </section>

      


      <header className="site-header container-fluid text-center row section-paddinog-img site-header-image mb-5">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "centeri",
          }}
          className="container-fluid column col-12 col-lg-6"
        >
          <div className="container-fluid row col-12">
            <div className="col-12 container-fluid">
              {/* <h2>
                <div className="text-primary">SHEROES in STEM</div>
                <span className="text-dark theme">
                  Empowering Girls Through Artificial Intelligence and Robotics
                </span>
              </h2> */}
             
            </div>
          </div>
          <div
            style={{ flexDirection: "column" }}
            className="container-fluid d-flex justify-content-end  col-12  mt-3"
          >
            We believe in the power of partnership and community involvement, recognizing that real change occurs when we unite our efforts toward a common goal. Together, we champion the rights of blind students, enabling them to navigate their educational pathways with confidence and purpose. The Lady Gladys Gordon Foundation is more than an organization; it is a movement dedicated to transforming lives and illuminating the future for blind individuals, ensuring that no pupil is left behind in the quest for knowledge and self-actualization.
            
          </div>
          <Link
                to="/sheroes-in-stem"
                target="_blank"
                className="text-danger"
              >
                Read More <em className="mdi mdi-arrow-right-thin"></em>
              </Link>
        </div>
        <img
          src="images/ladygladys/20210509_163229.jpg"
          className="header-image img-fluid col-12 col-lg-5 border-bottom-right-radius border-top-left-radius"
          alt=""
        />
      </header>



      <section className="container-fluid activity-bg mb-5">
            <h3 className="container text-center mt-1 mb-1">
                <span className="text-center">Recent Activities</span>
            </h3>
            <div
                className="row container-"
                style={{ display: "flex", justifyContent: "center" }}
            >
                <img
                    src="images/ladygladys/20230709_164825.jpg"
                    className="header-image my-1 border-top-left-radius fade-in img-fluid col-12 col-lg-4"
                    alt=""
                    ref={(el) => (elementsRef.current[0] = el)}
                />
                <img
                    src="images/ladygladys/20220715_172039.jpg"
                    className="header-image my-1 fade-in img-fluid col-12 col-lg-4"
                    alt=""
                    ref={(el) => (elementsRef.current[1] = el)}
                />
                <img
                    src="images/ladygladys/20220708_141641.jpg"
                    className="header-image my-1 border-bottom-right-radius fade-in img-fluid col-12 col-lg-4"
                    alt=""
                    ref={(el) => (elementsRef.current[2] = el)}
                />
            </div>
        </section>





      <div className="container mt-3">

      </div>

      <Footer />
    </div>
  );
}
