import React, { useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import axios from "axios"; // Import Axios

export default function Payment() {
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Send form data using Axios
    axios
      .post("https://fresh-link.onrender.com/contact", formData)
      .then((response) => {
        console.log(response.data);
        window.location.reload();

        // Handle success
      })
      .catch((error) => {
        console.error(error);
        window.alert(error);
        // Handle error
      });
  };

  return (
    <div>
      <Header />

      <header className="site-header section-padding section-padding-img site-header-image">
        <div className="container d-block ">
          <div className="row">
            <div className="col-lg-10 col-12 header-info">
              <h2>
                <span className="d-block text-primary">Donate to support us</span>
              </h2>
            </div>
          </div>
        </div>
      </header>

      <section className="contact section-padding">
        <div className="container">
          <div className="row">
            <div className="col-lg-7 col-12">
              <h5 className="mb-4">
                <span>
                  Follow the steps below to danate to support the blind
                </span>
              </h5>
              <div className="h5">
                Dial *170# <br />
                <br />
                Enter option 2 for MoMoPay & Pay Bill <br />
                <br />
                Choose 1 for MoMoPay
                <br />
              
                <br />
                Enter the Merchant ID (8 4 9 0 7 8).
                <br />
                <br />
                Enter the amount to be paid. <br />
                <br />
                Enter your reference <br />
              </div>
            </div>
            <div className="col-lg-5 col-12 mt-5 ms-auto">
              <div className="row">
                <div className="col-md-6 border-bottom border-end contact-info">
                  <h6 className="mb-3">Phone</h6>
                  <a href="tel:+233 24 762 8974" className="custom-link">
                    +233 24 762 8974
                    <i className="bi-arrow-right ms-2"></i>
                  </a>
                  <br />
                  <a href="tel:+233 24 498 7634" className="custom-link">
                    +233 24 498 7634
                    <i className="bi-arrow-right ms-2"></i>
                  </a>
                </div>
                <div className="col-md-6 contact-info">
                  <h6 className="mb-3">Email</h6>
                  <a href="mailto:studio@company.com" className="custom-link">
                    ladygladysgordonfoundation@gmail.com
                    <i className="bi-arrow-right ms-2"></i>
                  </a>
                </div>
                <div className="col-md-6 border-top border-end contact-info">
                  <h6 className="mb-3">Our Office</h6>
                  <p className="text-muted">We are located in Cape Coast</p>
                </div>
                <div className="col-md-6 border-top contact-info">
                  <h6 className="mb-3">Our Socials</h6>
                  <ul className="social-icon">
                    {/* <li><a target="none" href="https://www.facebook.com/molexfoundationafrica" className="social-icon-link bi-facebook"></a></li>
                                        <li><a target="none" href="https://www.linkedin.com/company/molex-foundation-africa/" className="social-icon-link bi-linkedin"></a></li>

                                        <li><a target="none" href="https://www.youtube.com/@molexfoundationafrica4635" className="social-icon-link bi-youtube"></a></li>
                                        <li><a target="none" href="https://www.instagram.com/molex_foundation_21" className="social-icon-link bi-instagram"></a></li>
                                        <li><a target="none" href="https://wa.me/0555089255" className="social-icon-link bi-whatsapp"></a></li> */}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <header className="site-header container site-header-image">
        <div className="container">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3973.926984403735!2d-1.2930430855529191!3d5.115469839346532!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xfddfed6fc798569%3A0x7531c2a02fe48636!2sUniversity%20Of%20Cape%20Coast!5e0!3m2!1sen!2sgh!4v1673617004224!5m2!1sen!2sgh"
            width="100%"
            height="460"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
            className="map"
          ></iframe>
        </div>
      </header>

      <Footer />
    </div>
  );
}
