// Mission.jsx
import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";

import Header from "./Header";
import Footer from "./Footer";
import SideNav from "./SideNav";

const longText = `<p>The Lady Gladys Gordon Foundation was officially established in June 2018, a significant milestone driven by the vision and commitment of its founder, Gladys Gordon. Recognizing the urgent need for support among blind pupils, Gladys, alongside her dedicated collaborators, Rev. Augustine Antwi and Samuel Awotwi, sought to create a transformative initiative that would provide essential financial assistance to students attending the Cape Coast School for the Deaf/Blind.</p> <p>
    From the very beginning, the foundation aimed to address the numerous challenges faced by these vulnerable students, many of whom come from economically disadvantaged backgrounds. The trio's shared passion for advocacy and education propelled them to take action, and they worked tirelessly to establish a framework that would ensure that blind pupils had access to the resources necessary for their academic and personal development. </p>
<p>
    With the foundation's inception, they embarked on a mission to not only alleviate financial burdens but also to empower these students by fostering an inclusive educational environment. This endeavor included securing funds to assist with tuition, purchasing learning materials, and providing support for essential needs such as transportation and housing.</p>
<p>
    The collective efforts of Gladys, Rev. Augustine, and Samuel marked the beginning of a journey dedicated to uplifting the lives of blind pupils. Their commitment laid a strong foundation for future initiatives aimed at improving the quality of education and life for these students, ensuring that they have the opportunities they deserve to succeed and thrive in their academic pursuits. Through the establishment of the Lady Gladys Gordon Foundation, they opened doors for countless blind students, paving the way for a brighter future for individuals who have historically faced significant barriers in education and society.</p>
`;

function Mission() {
  const missionRef = useRef(null);
  const visionRef = useRef(null);
  const coreValuesRef = useRef(null);
  const historyRef = useRef(null);
  const [showMore, setShowMore] = useState(false);

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };

  const scrollToSection = (ref) => {
    ref.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div>
      <Header />
      <section className="testimonial section-padding">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 mx-auto text-center-no">
              <div className="slick-testimonial" ref={missionRef}>
                <div className="slick-testimonialp-caption">
                  <p className="mission-text mb-5 pt-5 pt-lg-0">
                    <h5 className="text-center-no mt-5">
                      <span className="mt-5">Our Mission</span>
                    </h5>
                    To create a conducive atmosphere for blind students to
                    unearth their talents and improve the quality of life for
                    them.
                  </p>
                </div>
              </div>

              <div className="slick-testimonial" ref={visionRef}>
                <div className="slick-testimonialp-caption">
                  <p className="mission-text mb-5">
                    <h5 className="text-center-no mt-5">
                      <span>Our</span> Vision
                    </h5>
                    <div className="vision-text">
                      <p>
                        <em className="mdi mdi-checkbox-intermediate m-1"></em>
                        Empower blind students by creating an inclusive and
                        nurturing environment.
                      </p>
                      <p>
                        <em className="mdi mdi-checkbox-intermediate m-1"></em>
                        Foster personal growth and academic excellence for every
                        blind individual.
                      </p>
                      <p>
                        <em className="mdi mdi-checkbox-intermediate m-1"></em>
                        Ensure every blind student has the opportunity to thrive
                        and contribute meaningfully to society.
                      </p>

                      <p>
                        <em className="mdi mdi-checkbox-intermediate m-1"></em>
                        Support blind students in unearthing and harnessing
                        their unique talents.
                      </p>

                      <p>
                        <em className="mdi mdi-checkbox-intermediate m-1"></em>
                        Promote lifelong learning and development for blind
                        individuals.
                      </p>

                      {/* Add other vision points */}
                    </div>
                  </p>
                </div>
              </div>

              <div className="slick-testimonial" ref={coreValuesRef}>
                <div className="slick-testimonialp-caption">
                  <p className="mission-text mb-5">
                    <h5 className="text-center-no mt-5">
                      <span>Our</span> Core values
                    </h5>
                    <div className="vision-text">
                      <p>
                        <em className="mdi mdi-checkbox-intermediate m-1"></em>
                        Inclusivity
                      </p>
                      <p>
                        <em className="mdi mdi-checkbox-intermediate m-1"></em>
                        Empowerment
                      </p>
                      <p>
                        <em className="mdi mdi-checkbox-intermediate m-1"></em>
                        Integrity
                      </p>
                      {/* <p>
                        <em className="mdi mdi-checkbox-intermediate m-1"></em>
                        Combat against Sexual and Gender Based Violence.
                      </p> */}
                      <p>
                        <em className="mdi mdi-checkbox-intermediate m-1"></em>
                        Person Focused
                      </p>
                      <p>
                        <em className="mdi mdi-checkbox-intermediate m-1"></em>
                        Respect
                      </p>

                      <p>
                        <em className="mdi mdi-checkbox-intermediate m-1"></em>
                        Excellence
                      </p>
                      {/* Add other core values */}
                    </div>
                  </p>
                </div>
              </div>
              
              <div className="slick-testimonial" ref={historyRef}>
                <div className="slick-testimonialp-caption">
                  <p className="mission-text mb-5">
                    <h5 className="text-center-no mt-5">
                      <span>History</span>
                    </h5>
                    <div className="vision-text">
                      {showMore ? (
                        <>
                          <p
                            style={{ textAlign: "justify" }}
                            dangerouslySetInnerHTML={{ __html: longText }}
                          ></p>{" "}
                          <br />
                          <br />
                          <span
                            onClick={toggleShowMore}
                            className="hist-more mt-5"
                          >
                            Read less
                          </span>
                        </>
                      ) : (
                        <>
                          Established in June 2018 by Gladys Gordon with the
                          assistance of Rev. Augustine Antwi and Samuel Awotwi
                          to provide financial assistance to blind pupils at the
                          Cape Coast School for the Deaf/Blind. <br />
                          <br />
                          <span onClick={toggleShowMore} className="hist-more mt-5">
                  Read more
                </span>
                        </>
                      )}
                    </div>
                  </p>
                </div>
              </div>
            </div>

            <SideNav
              scrollToMission={() => scrollToSection(missionRef)}
              scrollToVision={() => scrollToSection(visionRef)}
              scrollToCoreValues={() => scrollToSection(coreValuesRef)}
              scrollToHistory={() => scrollToSection(historyRef)}
            />
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
}

export default Mission;
