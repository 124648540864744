import React, { useState, useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";

function TeamMemberModal({ id, pic }) {
  return (
    <div
      className="modal fade container-fluid"
      id={`modal-${id}`} // Unique ID for each modal
      tabIndex="-1"
      aria-labelledby={`modal-${id}-label`}
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered modal-xl">
        <div className="modal-content border-0">
          <div className="modal-header flex-column">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <img src={pic} alt={`Picture`} style={{ width: "100%", height: "auto" }} />
          </div>
        </div>
      </div>
    </div>
  );
}

const ImageList = () => {
  const [imageUrls, setImageUrls] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const imagesPerPage = 8; // Number of images per page

  useEffect(() => {
    const fetchImageUrls = async () => {
      try {
        const response = await fetch("https://fresh-link.onrender.com/api/images/");
        if (!response.ok) {
          throw new Error("Failed to fetch image URLs");
        }
        const data = await response.json();
        setImageUrls(data);
      } catch (error) {
        console.error("Error retrieving image URLs:", error);
      }
    };

    fetchImageUrls();
  }, []);

  // Pagination logic
  const totalPages = Math.ceil(imageUrls.length / imagesPerPage);
  const startIndex = (currentPage - 1) * imagesPerPage;
  const currentImages = imageUrls.slice(startIndex, startIndex + imagesPerPage); // Apply reverse here

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <div>
      <Header />

      <div className="container gallery-container section-padding mt-5">
        <div className="row">
          {currentImages.map((pic, index) => (
            <div key={index} className="col-lg-3 col-md-4 col-6 mb-4">
              <img
                src={pic}
                alt={`Picture ${index}`}
                style={{ maxWidth: "100%", height: "auto" }}
                data-bs-toggle="modal"
                data-bs-target={`#modal-${startIndex + index}`} // Adjust modal ID
              />
            </div>
          ))}
        </div>

        {/* Pagination Controls */}
        <div className="pagination-controls text-center">
          <button 
            className="btn btn-primary mx-2" 
            onClick={handlePreviousPage} 
            disabled={currentPage === 1}
          >
            Previous
          </button>
          <span>{`Page ${currentPage} of ${totalPages}`}</span>
          <button 
            className="btn btn-primary mx-2" 
            onClick={handleNextPage} 
            disabled={currentPage === totalPages}
          >
            Next
          </button>
        </div>
      </div>

      {currentImages.map((pic, index) => (
        <TeamMemberModal key={startIndex + index} id={startIndex + index} pic={pic}/>
      ))}

      <Footer />
    </div>
  );
};

export default ImageList;
