import { React, useEffect, useState, useRef } from "react";
import Header from "./Header";
import Footer from "./Footer";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "./css/Home.css";
import "slick-carousel/slick/slick-theme.css";
import YouTubeEmbed from "./YouTube";
import VideoFrame from "./Video";
import pics from "../data/robotics";

function NavItem({ id, text, active }) {
  return (
    <li className="nav-item" role="presentation">
      <button
        className={`nav-link${active ? " active" : ""}`}
        id={id}
        data-bs-toggle="pill"
        data-bs-target={`#${id}`}
        type="button"
        role="tab"
        aria-controls={id}
        aria-selected={active}
      >
        {text}
      </button>
    </li>
  );
}

function TabContent({ id, active, children }) {
  return (
    <div
      className={`tab-pane fade${active ? " show active" : ""}`}
      id={id}
      role="tabpanel"
      aria-labelledby={`${id}-tab`}
    >
      {children}
    </div>
  );
}

function TeamMemberModal({ id, pic }) {
  return (
    <div
      className="modal fade container-fluid"
      id={`modal-${id}`} // Unique ID for each modal
      tabIndex="-1"
      aria-labelledby={`modal-${id}-label`}
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered modal-xl">
        <div className="modal-content border-0">
          <div className="modal-header flex-column">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <img
              src={pic}
              alt={`Picture`}
              style={{ width: "100%", height: "auto" }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default function Programs() {
  const [countdown, setCountdown] = useState(0);
  const joyNewsRef = useRef(null);

  const settings = {
    dots: false,
    infinite: true,
    speed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true, // Enable auto-scrolling
    autoplaySpeed: 4000, // Set auto-scroll speed in milliseconds
  };

  const apiUrl = "https://fresh-link.onrender.com";

  const speakers = [
    {
      id: "patrick-essien",
      img: "2A1A1250.jpg",
      name: "Prof. Nana Ama Browne Klutse",
      role: "Head of Physics Department - University of Ghana, Vice Chair PCC",
      socialLinks: [
        { url: "#", icon: "bi-facebook" },
        { url: "#", icon: "bi-instagram" },
      ],
      bio: `
      `,
    },

    {
      id: "kuta",
      img: "Sarah_DeMartazzi_010924_098.jpg",
      name: "Sarah DeMartazzi",
      role: "Program Manager, Women in STEM - The Henry  Luce Foundation, New York",
      socialLinks: [
        { url: "#", icon: "bi-twitter" },
        { url: "#", icon: "bi-linkedin" },
        { url: "#", icon: "bi-envelope" },
      ],
      bio: ``,
    },

    {
      id: "don",
      img: "2A1A1342.jpg",
      name: "Dr. Regina Esi Turkson",
      role: "Senior Lecturer, Department of Computer Science and Information Technology - University of Cape Coast",
      socialLinks: [
        { url: "#", icon: "bi-youtube" },
        { url: "#", icon: "bi-whatsapp" },
        { url: "#", icon: "bi-instagram" },
      ],
      bio: ``,
    },
    {
      id: "kelly",
      img: "2A1A1270.jpg",
      name: "Adelaide Asantewa Asante",
      role: "Head of West Africa Office for UK Ecology and Hydrology",
      socialLinks: [
        { url: "#", icon: "bi-facebook" },
        { url: "#", icon: "bi-instagram" },
      ],
      bio: ``,
    },
    {
      id: "tsiwah",
      img: "2A1A1512.jpg",
      name: "Dr. Irene Kafui Vorsah Amponsah",
      role: "Lecturer, Department of Statistics - University of Cape Coast",
      socialLinks: [
        { url: "#", icon: "bi-twitter" },
        { url: "#", icon: "bi-linkedin" },
        { url: "#", icon: "bi-envelope" },
      ],
      bio: ``,
    },
  ];

  function fetchData() {
    fetch(apiUrl)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        console.log("Data received:", data);
        // Handle the data as needed
      })
      .catch((error) => {
        console.error("Error:", error);
        // Handle errors
      });
  }

  // Call fetchData every 10 seconds
  setInterval(fetchData, 10000);

  useEffect(() => {
    // Function to scroll to a section based on hash
    const scrollToHash = () => {
      const hash = window.location.hash;
      const sectionRef = hash === "#joynews" ? joyNewsRef.current : null;
      if (sectionRef) {
        sectionRef.scrollIntoView({ behavior: "smooth" });
      }
    };

    // Scroll to section if hash exists
    if (window.location.hash) {
      scrollToHash();
    }

    // Set the target date for the countdown (replace 'targetDate' with your desired date)
    const targetDate = new Date("2024-04-26T09:00:00").getTime();

    // Update the countdown every second
    const interval = setInterval(() => {
      // Get the current date and time
      const now = new Date().getTime();

      // Calculate the remaining time in milliseconds
      const distance = targetDate - now;

      // Calculate days, hours, minutes, and seconds
      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      // Update the countdown state
      setCountdown(`${days}d\t: \t${hours}h\t: \t${minutes}m\t: \t${seconds}s`);

      // If the countdown is over, clear the interval
      if (distance < 0) {
        clearInterval(interval);
        setCountdown("");
      }
    }, 1000);

    // Cleanup function to clear the interval
    return () => clearInterval(interval);
  }, []);

  return (
    <div>
      <Header />

      <section className="slick-slideshow-sheroes">
        <Slider {...settings} className="">
          <div className="slick-custom">
            <img
              src="images/ladygladys/20220715_170100.jpg"
              className="img-fluid"
              alt=""
            />

            <div className="slick-bottom">
              <div className="container">
                <div className="row">
                  <div className="col-lg-6 col-10">
                    <h3 className="slick-title">OUR PROGRAMS</h3>
                    <h6 className="slick-title mt-lg-3 mb-lg-5 show-">
                      Advancing the Rights and Opportunities for Blind Students
                    </h6>
                    <Link to="/contact" className="btn custom-btn">
                      Get in touch
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Slider>
      </section>

      <section className="about section-paddking mt-lg-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-12 border-colori">
              <div className="tab-content mt-2" id="pills-tabContent">
                <TabContent id="pills-home" active>
                  <div className="row">
                    <div className="col-12">
                      <div className="d- flex-column justify-content-center h-100 ms-lg-4 mt-lg-0 mt-5">
                        <h6 className="mb-3 text-center">
                          <span>
                            {" "}
                            Donation to the Cape Coast School for the
                            Deaf/Blind.
                          </span>{" "}
                        </h6>
                        <p className="b-text">
                          In 2021, the Foundation undertook a significant
                          initiative aimed at providing vital support to the
                          blind pupils of the Cape Coast School for the
                          Deaf/Blind. This generous donation was a crucial step
                          in addressing the needs of these students, who often
                          face various challenges in their daily lives due to
                          limited resources.
                        </p>

                        <div className="mt-0 mt-lg-auto"></div>
                      </div>
                    </div>
                  </div>
                </TabContent>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="site-header container-fluid text-center row section-paddinog-img ">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "centeri",
          }}
          className="container-fluid column col-12 col-lg-6"
        >
          <div className="container-fluid row col-12">
            <div className="col-12 ">
              <h2>
                <div className="text-primary">Why the Donation?</div>
                <span className="text-dark theme">
                </span>
              </h2>
            </div>
          </div>
          <div
            style={{ flexDirection: "column" }}
            className="container-fluid d-flex justify-content-end  col-12  mt-3"
          >
            <p className="b-text text-justify col-lg-12">
              The foundation focused on providing essential items that would
              directly benefit the individual blind pupils. Among the donated
              items were provisions such as nutritious food products, toiletries
              for personal hygiene, and sanitary pads to ensure the health and
              comfort of the students. By supplying these basic necessities, the
              foundation aimed to enhance the quality of life for the pupils and
              promote their overall well-being.
            </p>
          </div>
        </div>
        <img
          src="images/ladygladys/20220715_170021.jpg"
          className="header-image img-fluid col-12 col-lg-5 border-bottom-right-radius border-top-left-radius"
          alt=""
        />
      </div>

      

      <div className="site-header d-flex row justify-content-center container-fluid text-center row section-paddiong-img ">   

        <div
          style={{
            display: "flex",
            width: "100%",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
          className="container-fluid column col-12 col-lg-6"
        >
          <div className="container-fluid row col-12">
            <div className="col-12 ">
              <h4>
                <div className="text-primary">Why the Donation?</div>
                <span className="text-dark theme">
                  {/*Empowering Girls Through Artificial Intelligence and Robotics*/}
                </span>
              </h4>
            </div>
          </div>
          <div
            style={{ flexDirection: "column" }}
            className="container d-flex justify-content-end  col-12  mt-3"
          >
            <p className="b-text text-justify col-lg-12">
              
A few days before the scheduled Gospel Music Concert to raise funds for donations, blind pupils from the Cape Coast School for the Deaf/Blind participated in interviews at various FM stations in Cape Coast. These interviews aimed to raise awareness about their needs and the importance of the upcoming event.
            </p>
          </div>
        </div>
     

        <img
          src="images/ladygladys/20220708_141641_1.jpg"
          className="header-image img-fluid col-12 col-lg-5"
          alt=""
        />
        <img
          src="images/ladygladys/20220707_163058_1.jpg"
          className="header-image img-fluid col-12 col-lg-5"
          alt=""
        />
        
      </div>


      <section className="container-fluid activity-bg mt-4 mb-5">
        <h4 className="container text-center mt-1 mb-0">
        <span> Other Activities</span>{" "}
        </h4>
        <div
          className="row container-"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <div class="card" className="col-12 col-lg-3 activity" style={{}}>
            <img
              class="card-img-top"
              src="images/ladygladys/20220213_132445_1.jpg"
              alt="Card image"
            />
            <div class="card-body">
              <h6 class="card-title">2023 donation to the blind pupils of Cape Coast School for the Deaf/ Blind.</h6>
              {/* <p class="card-text">Some example text.</p> */}
              {/* <a href="#" class="btn btn-primary">See Profile</a> */}
            </div>
          </div>


          <div class="card" className="col-12 col-lg-3 activity" style={{}}>
            <img
              class="card-img-top"
              src="images/ladygladys/IMG_20240721_151606_1.jpg"
              alt="Card image"
            />
            <div class="card-body">
              <h6 class="card-title">2024 donation to the blind pupils of Cape Coast School for the Deaf/ Blind.</h6>
              {/* <p class="card-text">Some example text.</p> */}
              {/* <a href="#" class="btn btn-primary">See Profile</a> */}
            </div>
          </div>

          <div class="card" className="col-12 col-lg-3 activity" style={{}}>
            <img
              class="card-img-top"
              src="images/ladygladys/20220710_200137_1.jpg"
              alt="Card image"
            />
            <div class="card-body">
              <h6 class="card-title">
              Citation to FM stations that offered a platform for us to publicize our programmes.
              </h6>
              {/* <p class="card-text">Some example text.</p> */}
              {/* <a href="#" class="btn btn-primary">See Profile</a> */}
            </div>

            
          </div>

                          {/* <img
                            src="images/ladygladys/20220708_144914.jpg"
                            className="header-image border-bottom-right-radius  img-fluid col-12 col-lg-4"
                            alt=""
                          /> */}
        </div>
      </section>



      {/* <div>
        <div className="container">
          <img src="img_avatar.png" alt="Avatar" className="image" />
          <div className="overlay">
            <div className="text">Hello World</div>
          </div>
        </div>
      </div> */}

     

      <section className="about section-paddking mt-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-12 border-colori">
              <div className="tab-content mt-0" id="pills-tabContent">
                <TabContent id="pills-home" active>
                  <div className="row">
                    <div className="col-12">
                      <div className="d- flex-column justify-content-center h-100 ms-lg-4 mt-lg-0 mt-0">
                        <h4 className="mb-3 text-center">
                          <span> Citation Presentation to Prof. Samuel Ato Duncan</span>{" "}
                        </h4>
                        {/* <p>Little Fashion templates comes with <Link to="sign-in.html">sign in</Link> / <Link to="sign-up.html">sign up</Link> pages, product listing / product detail, about, FAQs, and contact page.</p> */}
                        <p className="b-text">
                        We are pleased to acknowledge Prof. Samuel Ato Duncan, CEO of COA Research, for his invaluable support during our Gospel Music Concert in 2022. As the esteemed chair of the event, Prof. Duncan made a generous cash donation of Ten Thousand Ghana Cedis (GH¢10,000.00). This contribution significantly enhances the foundation's ability to support the blind pupils at the Cape Coast School for the Deaf/Blind, furthering our mission to empower and uplift these students. Thank you, Prof. Duncan, for your commitment to making a difference in the lives of our beneficiaries.
                        </p>

                        <div className="mt-0 mt-lg-auto"></div>

                        <header className="site-header container-fluid text-center row section-paddinog-img site-header-image">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    className="container-fluid column col-12"
                  >
                    
                  </div>
                  <img
                    src="images/ladygladys/WhatsApp Image 2024-09-16 at 21.32.41_c98d4888.jpg"
                    className="header-image border-top-left-radius  img-fluid col-12 col-lg-6"
                    alt=""
                  />
                  <img
                    src="images/ladygladys/WhatsApp Image 2024-09-16 at 21.32.39_479923ec.jpg"
                    className="header-image border-bottom-right-radius img-fluid col-12 col-lg-6"
                    alt=""
                  />
                  {/* <img
                    src="images/sheroes/photo_2024-09-23_15-42-04.jpg"
                    className="header-image  img-fluid col-12 col-lg-6"
                    alt=""
                  />
                  <img
                    src="images/sheroes/01.jpeg"
                    className="header-image border-bottom-right-radius  img-fluid col-12 col-lg-6"
                    alt=""
                  /> */}
                  
                </header>
                      </div>
                    </div>
                  </div>
                </TabContent>
              </div>
            </div>
          </div>
        </div>
      </section>



      <Footer />
    </div>
  );
}
