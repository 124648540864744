import { React, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./css/Home.css";

export default function Footer() {
  return (
    <div>
      <footer className="site-footer">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-10 me-auto mb-4">
            <h5 className="text-white mb-3">info</h5>
              {/* <h4 className="text-white mb-3"><a href="index.html">MOLEX</a> Fashion</h4> */}
              <p className="copyright-text text-muted mt-lg-5 mb-4 mb-lg-0">
                Copyright © 2018 - 2024
              </p>
              <strong className="copyright-text text-muted mt-lg-5 mb-4 mb-lg-0">
                Lady Gladys Gordon Foundation
              </strong>
              <br />
              <br />
              <p className="copyright-text text-white">
                Designed and developed by <br />
                <a
                  href="#"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-white"
                >
                  Stephen Nyankson (+233 555 089255)
                </a>
              </p>
            </div>

            <div className="col-lg-5 col-12">
              <h5 className="text-white mb-3">Sitemap</h5>

              <ul className="footer-menu d-flex flex-wrap">

                <li className="footer-menu-item">
                  <Link to="/about" className="footer-menu-link">
                    About
                  </Link>
                </li>

                <li className="footer-menu-item">
                  <Link to="/grants" className="footer-menu-link">
                    Grants & Awards
                  </Link>
                </li>

                <li className="footer-menu-item">
                  <Link to="/gallery" className="footer-menu-link">
                    Gallery
                  </Link>
                </li>

                <li className="footer-menu-item">
                  <Link to="/contact" className="footer-menu-link">
                    Contact
                  </Link>
                </li>
              </ul>
            </div>

            <div className="col-lg-3 col-10">
              <h5 className="text-white mb-3">Social</h5>

              <ul className="social-icon">
                <li>
                  <a
                    target="none"
                    href="#"
                    className="social-icon-link bi-facebook"
                  ></a>
                </li>
                <li>
                  <a
                    target="none"
                    href="#"
                    className="social-icon-link bi-linkedin"
                  ></a>
                </li>

                <li>
                  <a
                    target="none"
                    href="#"
                    className="social-icon-link bi-youtube"
                  ></a>
                </li>
                <li>
                  <a
                    target="none"
                    href="#"
                    className="social-icon-link bi-instagram"
                  ></a>
                </li>
                <li>
                  <a
                    target="none"
                    href="#"
                    className="social-icon-link bi-whatsapp"
                  ></a>
                </li>
              </ul>
              {/* <div className="form-floating">
                <input
                  type="text"
                  name="name"
                  id="name"
                  className="form-control"
                  placeholder="Full name"
                  required
                />
                <label htmlFor="name">Full name</label>
                <button className="btn btn-danger">Submit</button>
              </div> */}
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}
