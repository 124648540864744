import React, { useRef } from "react";
import { Link } from "react-router-dom";

function Header() {
  const missionRef = useRef(null);
  const visionRef = useRef(null);
  const coreValuesRef = useRef(null);
  const historyRef = useRef(null);

  const reloadPage = () => {
    window.location.reload();
  };
  const baseUrl =
    window.location.protocol +
    "//" +
    window.location.hostname +
    (window.location.port ? ":" + window.location.port : "");

  return (
    <header>
      <nav className="navbar navbar-expand-lg">
        <div className="container">
         

          <Link to={baseUrl} className="navbar-brand">
            <img src="images/logo/logo1.png" className="logo" />
            <strong>
              {/* <span>MOLEX</span> Foundation */}
            </strong>
          </Link>

          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse" id="navbarNav">
            <br />
            <br />
            <br />
            <ul className="navbar-nav mx-auto">
              <li className="nav-item">
                <Link to={baseUrl} className="nav-link active">
                  Home
                </Link>
              </li>

              <li className="nav-item">
                <Link to='/programs' className="nav-link">Programs</Link>
                {/* <div className="dropdown-content">
                  <div className="dropdown container-fluid">
                    <Link to="" className="Stem">
                      <em
                        className="mdi mdi-checkbox-intermediate m-1"
                        style={{ color: "white" }}
                      ></em>
                      STEM Programs
                    </Link>
                    <div className="STEM-MENU mb-3" style={{}}>
                      <Link to="/sheroes-in-stem" className="dropdown-item">
                        <em
                          className="mdi mdi-checkbox-marked-circle-outline m-2"
                          style={{ color: "white" }}
                        ></em>
                        SHEROES in STEM
                      </Link>
                     
                    </div>
                  </div>

                  <Link to="/program">
                 
                    <br />
                    <br />
                    <br />
                  </Link>
                  <Link to="/program">The Pinnacle Journey</Link>
                  <Link to="/guiding-stars">Guiding Stars</Link>
                  <a href="#">Time with MOLEX</a>
                </div> */}
              </li>

              <li className="nav-item dropdown">
                <div className="nav-link dropbtn">About</div>
                <div className="dropdown-content">
                  <Link to="/about" ref={missionRef}>
                    Our Mission
                  </Link>
                  <Link to="/about" ref={visionRef}>
                    Our Vision
                  </Link>
                  <Link to="/about" ref={coreValuesRef}>
                    Our Core values
                  </Link>
                  <Link to="/about" ref={historyRef}>
                    History
                  </Link>
                  <Link to="/about/executives">Executives</Link>
                  {/* <Link to="/about/board">Board of Directors</Link> */}
                </div>
              </li>
              


              {/* <li className="nav-item">
                <Link to="/gallery" className="nav-link">
                  Gallery
                </Link>
              </li> */}

              {/* <li className="nav-item">
                <Link to="/news" className="nav-link">
                  MOLEX News
                </Link>
              </li> */}

              <li className="nav-item dropdown">
                <Link to="/support" className="nav-link dropbtn">Become a Sponsor</Link>
                {/* <div className="dropdown-content">
                  <Link to="/volunteer">
                    Become a Sponsor
                  </Link>
                  <Link to={baseUrl + "/grants"}>
                  Grants & Partnership
                  </Link>
                 
                </div> */}
              </li>

              <li className="nav-item">
                <Link to={baseUrl + "/contact"} className="nav-link">
                  Contact
                </Link>
              </li>
            </ul>
            {/* <Link className="btn btn-primary rounded">Donate</Link> */}
          </div>
        </div>
      </nav>
    </header>
  );
}

export default Header;
